!(function(r) {
  if ('object' == typeof exports && 'undefined' != typeof module) module.exports = r()
  else if ('function' == typeof define && define.amd) define([], r)
  else {
    var t
    ;(t =
      'undefined' != typeof window
        ? window
        : 'undefined' != typeof global
        ? global
        : 'undefined' != typeof self
        ? self
        : this),
      (t.crossfilter = r())
  }
})(function() {
  return (function() {
    function r(t, e, n) {
      function o(u, f) {
        if (!e[u]) {
          if (!t[u]) {
            var a = 'function' == typeof require && require
            if (!f && a) return a(u, !0)
            if (i) return i(u, !0)
            var c = new Error("Cannot find module '" + u + "'")
            throw ((c.code = 'MODULE_NOT_FOUND'), c)
          }
          var s = (e[u] = { exports: {} })
          t[u][0].call(
            s.exports,
            function(r) {
              var e = t[u][1][r]
              return o(e || r)
            },
            s,
            s.exports,
            r,
            t,
            e,
            n
          )
        }
        return e[u].exports
      }
      for (var i = 'function' == typeof require && require, u = 0; u < n.length; u++)
        o(n[u])
      return o
    }
    return r
  })()(
    {
      1: [
        function(r, t) {
          t.exports = r('./src/crossfilter').crossfilter
        },
        { './src/crossfilter': 6 },
      ],
      2: [
        function(r, t) {
          ;(function(r) {
            function e(r, t) {
              return null == r ? void 0 : r[t]
            }
            function n(r) {
              var t = !1
              if (null != r && 'function' != typeof r.toString)
                try {
                  t = !!(r + '')
                } catch (e) {}
              return t
            }
            function o(r) {
              var t = -1,
                e = r ? r.length : 0
              for (this.clear(); ++t < e; ) {
                var n = r[t]
                this.set(n[0], n[1])
              }
            }
            function i() {
              this.__data__ = dt ? dt(null) : {}
            }
            function u(r) {
              return this.has(r) && delete this.__data__[r]
            }
            function f(r) {
              var t = this.__data__
              if (dt) {
                var e = t[r]
                return e === T ? void 0 : e
              }
              return ct.call(t, r) ? t[r] : void 0
            }
            function a(r) {
              var t = this.__data__
              return dt ? void 0 !== t[r] : ct.call(t, r)
            }
            function c(r, t) {
              var e = this.__data__
              return (e[r] = dt && void 0 === t ? T : t), this
            }
            function s(r) {
              var t = -1,
                e = r ? r.length : 0
              for (this.clear(); ++t < e; ) {
                var n = r[t]
                this.set(n[0], n[1])
              }
            }
            function l() {
              this.__data__ = []
            }
            function h(r) {
              var t = this.__data__,
                e = w(t, r)
              if (0 > e) return !1
              var n = t.length - 1
              return e == n ? t.pop() : pt.call(t, e, 1), !0
            }
            function p(r) {
              var t = this.__data__,
                e = w(t, r)
              return 0 > e ? void 0 : t[e][1]
            }
            function v(r) {
              return w(this.__data__, r) > -1
            }
            function d(r, t) {
              var e = this.__data__,
                n = w(e, r)
              return 0 > n ? e.push([r, t]) : (e[n][1] = t), this
            }
            function y(r) {
              var t = -1,
                e = r ? r.length : 0
              for (this.clear(); ++t < e; ) {
                var n = r[t]
                this.set(n[0], n[1])
              }
            }
            function g() {
              this.__data__ = { hash: new o(), map: new (vt || s)(), string: new o() }
            }
            function b(r) {
              return A(this, r).delete(r)
            }
            function _(r) {
              return A(this, r).get(r)
            }
            function x(r) {
              return A(this, r).has(r)
            }
            function m(r, t) {
              return A(this, r).set(r, t), this
            }
            function w(r, t) {
              for (var e = r.length; e--; ) if (q(r[e][0], t)) return e
              return -1
            }
            function E(r) {
              if (!R(r) || M(r)) return !1
              var t = N(r) || n(r) ? lt : Z
              return t.test(C(r))
            }
            function O(r) {
              if ('string' == typeof r) return r
              if (D(r)) return gt ? gt.call(r) : ''
              var t = r + ''
              return '0' == t && 1 / r == -W ? '-0' : t
            }
            function k(r) {
              return _t(r) ? r : bt(r)
            }
            function A(r, t) {
              var e = r.__data__
              return S(t) ? e['string' == typeof t ? 'string' : 'hash'] : e.map
            }
            function z(r, t) {
              var n = e(r, t)
              return E(n) ? n : void 0
            }
            function j(r, t) {
              if (_t(r)) return !1
              var e = typeof r
              return 'number' == e || 'symbol' == e || 'boolean' == e || null == r || D(r)
                ? !0
                : K.test(r) || !H.test(r) || (null != t && r in Object(t))
            }
            function S(r) {
              var t = typeof r
              return 'string' == t || 'number' == t || 'symbol' == t || 'boolean' == t
                ? '__proto__' !== r
                : null === r
            }
            function M(r) {
              return !!ft && ft in r
            }
            function F(r) {
              if ('string' == typeof r || D(r)) return r
              var t = r + ''
              return '0' == t && 1 / r == -W ? '-0' : t
            }
            function C(r) {
              if (null != r) {
                try {
                  return at.call(r)
                } catch (t) {}
                try {
                  return r + ''
                } catch (t) {}
              }
              return ''
            }
            function $(r, t) {
              if ('function' != typeof r || (t && 'function' != typeof t))
                throw new TypeError(P)
              var e = function() {
                var n = arguments,
                  o = t ? t.apply(this, n) : n[0],
                  i = e.cache
                if (i.has(o)) return i.get(o)
                var u = r.apply(this, n)
                return (e.cache = i.set(o, u)), u
              }
              return (e.cache = new ($.Cache || y)()), e
            }
            function q(r, t) {
              return r === t || (r !== r && t !== t)
            }
            function N(r) {
              var t = R(r) ? st.call(r) : ''
              return t == J || t == G
            }
            function R(r) {
              var t = typeof r
              return !!r && ('object' == t || 'function' == t)
            }
            function U(r) {
              return !!r && 'object' == typeof r
            }
            function D(r) {
              return 'symbol' == typeof r || (U(r) && st.call(r) == B)
            }
            function I(r) {
              return null == r ? '' : O(r)
            }
            function L(r, t, e) {
              t = j(t, r) ? [t] : k(t)
              var n = -1,
                o = t.length
              for (o || ((r = void 0), (o = 1)); ++n < o; ) {
                var i = null == r ? void 0 : r[F(t[n])]
                void 0 === i && ((n = o), (i = e)), (r = N(i) ? i.call(r) : i)
              }
              return r
            }
            var P = 'Expected a function',
              T = '__lodash_hash_undefined__',
              W = 1 / 0,
              J = '[object Function]',
              G = '[object GeneratorFunction]',
              B = '[object Symbol]',
              H = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/,
              K = /^\w*$/,
              Q = /^\./,
              V = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g,
              X = /[\\^$.*+?()[\]{}|]/g,
              Y = /\\(\\)?/g,
              Z = /^\[object .+?Constructor\]$/,
              rt = 'object' == typeof r && r && r.Object === Object && r,
              tt = 'object' == typeof self && self && self.Object === Object && self,
              et = rt || tt || Function('return this')(),
              nt = Array.prototype,
              ot = Function.prototype,
              it = Object.prototype,
              ut = et['__core-js_shared__'],
              ft = (function() {
                var r = /[^.]+$/.exec((ut && ut.keys && ut.keys.IE_PROTO) || '')
                return r ? 'Symbol(src)_1.' + r : ''
              })(),
              at = ot.toString,
              ct = it.hasOwnProperty,
              st = it.toString,
              lt = RegExp(
                '^' +
                  at
                    .call(ct)
                    .replace(X, '\\$&')
                    .replace(
                      /hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g,
                      '$1.*?'
                    ) +
                  '$'
              ),
              ht = et.Symbol,
              pt = nt.splice,
              vt = z(et, 'Map'),
              dt = z(Object, 'create'),
              yt = ht ? ht.prototype : void 0,
              gt = yt ? yt.toString : void 0
            ;(o.prototype.clear = i),
              (o.prototype.delete = u),
              (o.prototype.get = f),
              (o.prototype.has = a),
              (o.prototype.set = c),
              (s.prototype.clear = l),
              (s.prototype.delete = h),
              (s.prototype.get = p),
              (s.prototype.has = v),
              (s.prototype.set = d),
              (y.prototype.clear = g),
              (y.prototype.delete = b),
              (y.prototype.get = _),
              (y.prototype.has = x),
              (y.prototype.set = m)
            var bt = $(function(r) {
              r = I(r)
              var t = []
              return (
                Q.test(r) && t.push(''),
                r.replace(V, function(r, e, n, o) {
                  t.push(n ? o.replace(Y, '$1') : e || r)
                }),
                t
              )
            })
            $.Cache = y
            var _t = Array.isArray
            t.exports = L
          }.call(
            this,
            'undefined' != typeof global
              ? global
              : 'undefined' != typeof self
              ? self
              : 'undefined' != typeof window
              ? window
              : {}
          ))
        },
        {},
      ],
      3: [
        function(r, t) {
          t.exports = { version: '1.4.7' }
        },
        {},
      ],
      4: [
        function(r, t) {
          function e(r) {
            for (var t = new Array(r), e = -1; ++e < r; ) t[e] = 0
            return t
          }
          function n(r, t) {
            for (var e = r.length; t > e; ) r[e++] = 0
            return r
          }
          function o(r, t) {
            if (t > 32) throw new Error('invalid array width!')
            return r
          }
          function i(r) {
            ;(this.length = r),
              (this.subarrays = 1),
              (this.width = 8),
              (this.masks = { 0: 0 }),
              (this[0] = u(r))
          }
          if ('undefined' != typeof Uint8Array)
            var u = function(r) {
                return new Uint8Array(r)
              },
              f = function(r) {
                return new Uint16Array(r)
              },
              a = function(r) {
                return new Uint32Array(r)
              },
              c = function(r, t) {
                if (r.length >= t) return r
                var e = new r.constructor(t)
                return e.set(r), e
              },
              s = function(r, t) {
                var e
                switch (t) {
                  case 16:
                    e = f(r.length)
                    break
                  case 32:
                    e = a(r.length)
                    break
                  default:
                    throw new Error('invalid array width!')
                }
                return e.set(r), e
              }
          ;(i.prototype.lengthen = function(r) {
            var t, e
            for (t = 0, e = this.subarrays; e > t; ++t) this[t] = c(this[t], r)
            this.length = r
          }),
            (i.prototype.add = function() {
              var r, t, e, n, o
              for (n = 0, o = this.subarrays; o > n; ++n)
                if (
                  ((r = this.masks[n]),
                  (t = this.width - 32 * n),
                  (e = ~r & -~r),
                  !(t >= 32) || e)
                )
                  return (
                    32 > t &&
                      e & (1 << t) &&
                      ((this[n] = s(this[n], (t <<= 1))), (this.width = 32 * n + t)),
                    (this.masks[n] |= e),
                    { offset: n, one: e }
                  )
              return (
                (this[this.subarrays] = u(this.length)),
                (this.masks[this.subarrays] = 1),
                (this.width += 8),
                { offset: this.subarrays++, one: 1 }
              )
            }),
            (i.prototype.copy = function(r, t) {
              var e, n
              for (e = 0, n = this.subarrays; n > e; ++e) this[e][r] = this[e][t]
            }),
            (i.prototype.truncate = function(r) {
              var t, e
              for (t = 0, e = this.subarrays; e > t; ++t) {
                for (var n = this.length - 1; n >= r; n--) this[t][n] = 0
                this[t].length = r
              }
              this.length = r
            }),
            (i.prototype.zero = function(r) {
              var t, e
              for (t = 0, e = this.subarrays; e > t; ++t) if (this[t][r]) return !1
              return !0
            }),
            (i.prototype.zeroExcept = function(r, t, e) {
              var n, o
              for (n = 0, o = this.subarrays; o > n; ++n)
                if (n === t ? this[n][r] & e : this[n][r]) return !1
              return !0
            }),
            (i.prototype.zeroExceptMask = function(r, t) {
              var e, n
              for (e = 0, n = this.subarrays; n > e; ++e) if (this[e][r] & t[e]) return !1
              return !0
            }),
            (i.prototype.only = function(r, t, e) {
              var n, o
              for (n = 0, o = this.subarrays; o > n; ++n)
                if (this[n][r] != (n === t ? e : 0)) return !1
              return !0
            }),
            (i.prototype.onlyExcept = function(r, t, e, n, o) {
              var i, u, f
              for (u = 0, f = this.subarrays; f > u; ++u)
                if (((i = this[u][r]), u === t && (i &= e), i != (u === n ? o : 0)))
                  return !1
              return !0
            }),
            (t.exports = {
              array8: e,
              array16: e,
              array32: e,
              arrayLengthen: n,
              arrayWiden: o,
              bitarray: i,
            })
        },
        {},
      ],
      5: [
        function(r, t) {
          'use strict'
          function e(r) {
            function t(t, e, n, o) {
              for (; o > n; ) {
                var i = (n + o) >>> 1
                r(t[i]) < e ? (n = i + 1) : (o = i)
              }
              return n
            }
            function e(t, e, n, o) {
              for (; o > n; ) {
                var i = (n + o) >>> 1
                e < r(t[i]) ? (o = i) : (n = i + 1)
              }
              return n
            }
            return (e.right = e), (e.left = t), e
          }
          var n = r('./identity')
          ;(t.exports = e(n)), (t.exports.by = e)
        },
        { './identity': 10 },
      ],
      6: [
        function(r, t, e) {
          'use strict'
          function n() {
            function r(r) {
              var t = M,
                e = r.length
              return (
                e &&
                  ((S = S.concat(r)),
                  z.lengthen((M += e)),
                  C.forEach(function(n) {
                    n(r, t, e)
                  }),
                  A('dataAdded')),
                j
              )
            }
            function t(r) {
              for (
                var t = o(M, M),
                  e = [],
                  n = 'function' == typeof r,
                  i = function(t) {
                    return n ? r(S[t], t) : z.zero(t)
                  },
                  u = 0,
                  f = 0;
                M > u;
                ++u
              )
                i(u) ? (e.push(u), (t[u] = m)) : (t[u] = f++)
              F.forEach(function(r) {
                r(-1, -1, [], e, !0)
              }),
                $.forEach(function(r) {
                  r(t)
                })
              for (var a = 0, c = 0; M > a; ++a)
                t[a] !== m && (a !== c && (z.copy(c, a), (S[c] = S[a])), ++c)
              ;(S.length = M = c), z.truncate(c), A('dataRemoved')
            }
            function e(r) {
              var t,
                e,
                n,
                o,
                i = Array(z.subarrays)
              for (t = 0; t < z.subarrays; t++) i[t] = -1
              for (e = 0, n = r.length; n > e; e++)
                (o = r[e].id()), (i[o >> 7] &= ~(1 << (63 & o)))
              return i
            }
            function n(r, t) {
              var n = e(t || [])
              return z.zeroExceptMask(r, n)
            }
            function d(r, t) {
              function e(e, n, u) {
                if (t) {
                  ;(pt = 0), (A = 0), (ot = [])
                  for (var a = 0; a < e.length; a++)
                    for (A = 0, ot = r(e[a]); A < ot.length; A++) pt++
                  ;(Q = []), (Y = i(e.length)), (rt = o(pt, 1))
                  for (var c = i(pt), s = 0, l = 0; l < e.length; l++)
                    if (((ot = r(e[l])), ot.length))
                      for (Y[l] = ot.length, A = 0; A < ot.length; A++)
                        Q.push(ot[A]), (c[s] = l), s++
                    else (Y[l] = 0), ut.push(l + n)
                  var h = ft(i(pt), 0, pt)
                  ;(Q = y(Q, h)), (V = y(c, h))
                } else (Q = e.map(r)), (V = ft(i(u), 0, u)), (Q = y(Q, V))
                t && (u = pt)
                var p = at(Q),
                  v = p[0],
                  d = p[1]
                if (tt)
                  for (var g = 0; u > g; ++g)
                    tt(Q[g], g) || ((z[G][V[g] + n] |= W), t && (rt[g] = 1))
                else {
                  for (var b = 0; v > b; ++b) (z[G][V[b] + n] |= W), t && (rt[b] = 1)
                  for (var _ = d; u > _; ++_) (z[G][V[_] + n] |= W), t && (rt[_] = 1)
                }
                if (!n)
                  return (H = Q), (K = V), (X = Y), (Z = rt), (lt = v), (ht = d), void 0
                var x,
                  m = H,
                  w = K,
                  E = Z,
                  O = 0
                if (
                  ((a = 0),
                  t && ((x = n), (n = m.length), (u = pt)),
                  (H = t ? new Array(n + u) : new Array(M)),
                  (K = t ? new Array(n + u) : o(M, M)),
                  t && (Z = o(n + u, 1)),
                  t)
                ) {
                  var k = X.length
                  X = f.arrayLengthen(X, M)
                  for (var A = 0; M > A + k; A++) X[A + k] = Y[A]
                }
                for (var j = 0; n > a && u > O; ++j)
                  m[a] < Q[O]
                    ? ((H[j] = m[a]), t && (Z[j] = E[a]), (K[j] = w[a++]))
                    : ((H[j] = Q[O]), t && (Z[j] = rt[O]), (K[j] = V[O++] + (t ? x : n)))
                for (; n > a; ++a, ++j) (H[j] = m[a]), t && (Z[j] = E[a]), (K[j] = w[a])
                for (; u > O; ++O, ++j)
                  (H[j] = Q[O]), t && (Z[j] = rt[O]), (K[j] = V[O] + (t ? x : n))
                ;(p = at(H)), (lt = p[0]), (ht = p[1])
              }
              function n(r, t, e) {
                ct.forEach(function(r) {
                  r(Q, V, t, e)
                }),
                  (Q = V = null)
              }
              function d(r) {
                if (t) {
                  for (var e = 0, n = 0; e < ut.length; e++)
                    r[ut[e]] !== m && ((ut[n] = r[ut[e]]), n++)
                  for (ut.length = n, e = 0, n = 0; M > e; e++)
                    r[e] !== m && (n !== e && (X[n] = X[e]), n++)
                  X.length = n
                }
                for (var o, i = H.length, u = 0, f = 0; i > u; ++u)
                  (o = K[u]),
                    r[o] !== m &&
                      (u !== f && (H[f] = H[u]), (K[f] = r[o]), t && (Z[f] = Z[u]), ++f)
                for (H.length = f, t && (Z.length = f); i > f; ) K[f++] = 0
                var a = at(H)
                ;(lt = a[0]), (ht = a[1])
              }
              function _(r) {
                var e = r[0],
                  n = r[1]
                if (tt)
                  return (
                    (tt = null),
                    q(function(r, t) {
                      return t >= e && n > t
                    }, 0 === r[0] && r[1] === H.length),
                    (lt = e),
                    (ht = n),
                    it
                  )
                var o,
                  i,
                  u,
                  f = [],
                  a = [],
                  c = [],
                  s = []
                if (lt > e)
                  for (o = e, i = Math.min(lt, n); i > o; ++o) f.push(K[o]), c.push(o)
                else if (e > lt)
                  for (o = lt, i = Math.min(e, ht); i > o; ++o) a.push(K[o]), s.push(o)
                if (n > ht)
                  for (o = Math.max(e, ht), i = n; i > o; ++o) f.push(K[o]), c.push(o)
                else if (ht > n)
                  for (o = Math.max(lt, n), i = ht; i > o; ++o) a.push(K[o]), s.push(o)
                if (t) {
                  var l = [],
                    h = []
                  for (o = 0; o < f.length; o++)
                    X[f[o]]++,
                      (Z[c[o]] = 0),
                      1 === X[f[o]] && ((z[G][f[o]] ^= W), l.push(f[o]))
                  for (o = 0; o < a.length; o++)
                    X[a[o]]--,
                      (Z[s[o]] = 1),
                      0 === X[a[o]] && ((z[G][a[o]] ^= W), h.push(a[o]))
                  if (((f = l), (a = h), 0 === r[0] && r[1] === H.length))
                    for (o = 0; o < ut.length; o++)
                      z[G][(u = ut[o])] & W && ((z[G][u] ^= W), f.push(u))
                  else
                    for (o = 0; o < ut.length; o++)
                      z[G][(u = ut[o])] & W || ((z[G][u] ^= W), a.push(u))
                } else {
                  for (o = 0; o < f.length; o++) z[G][f[o]] ^= W
                  for (o = 0; o < a.length; o++) z[G][a[o]] ^= W
                }
                return (
                  (lt = e),
                  (ht = n),
                  F.forEach(function(r) {
                    r(W, G, f, a)
                  }),
                  A('filtered'),
                  it
                )
              }
              function w(r) {
                return null == r
                  ? k()
                  : Array.isArray(r)
                  ? O(r)
                  : 'function' == typeof r
                  ? j(r)
                  : E(r)
              }
              function E(r) {
                return (et = r), (nt = !0), _((at = a.filterExact(v, r))(H))
              }
              function O(r) {
                return (et = r), (nt = !0), _((at = a.filterRange(v, r))(H))
              }
              function k() {
                return (et = void 0), (nt = !1), _((at = a.filterAll)(H))
              }
              function j(r) {
                ;(et = r), (nt = !0), (tt = r), (at = a.filterAll), q(r, !1)
                var t = at(H)
                return (lt = t[0]), (ht = t[1]), it
              }
              function q(r, e) {
                var n,
                  o,
                  i,
                  u = [],
                  f = [],
                  a = [],
                  c = [],
                  s = H.length
                if (!t)
                  for (n = 0; s > n; ++n)
                    !(z[G][(o = K[n])] & W) ^ !!(i = r(H[n], n)) &&
                      (i ? u.push(o) : f.push(o))
                if (t)
                  for (n = 0; s > n; ++n)
                    r(H[n], n) ? (u.push(K[n]), a.push(n)) : (f.push(K[n]), c.push(n))
                if (t) {
                  var l = [],
                    h = []
                  for (n = 0; n < u.length; n++)
                    1 === Z[a[n]] &&
                      (X[u[n]]++,
                      (Z[a[n]] = 0),
                      1 === X[u[n]] && ((z[G][u[n]] ^= W), l.push(u[n])))
                  for (n = 0; n < f.length; n++)
                    0 === Z[c[n]] &&
                      (X[f[n]]--,
                      (Z[c[n]] = 1),
                      0 === X[f[n]] && ((z[G][f[n]] ^= W), h.push(f[n])))
                  if (((u = l), (f = h), e))
                    for (n = 0; n < ut.length; n++)
                      z[G][(o = ut[n])] & W && ((z[G][o] ^= W), u.push(o))
                  else
                    for (n = 0; n < ut.length; n++)
                      z[G][(o = ut[n])] & W || ((z[G][o] ^= W), f.push(o))
                } else {
                  for (n = 0; n < u.length; n++) z[G][u[n]] & W && (z[G][u[n]] &= J)
                  for (n = 0; n < f.length; n++) z[G][f[n]] & W || (z[G][f[n]] |= W)
                }
                F.forEach(function(r) {
                  r(W, G, u, f)
                }),
                  A('filtered')
              }
              function N() {
                return et
              }
              function R() {
                return nt
              }
              function U(r, e) {
                var n,
                  o = [],
                  i = ht,
                  u = 0
                for (e && e > 0 && (u = e); --i >= lt && r > 0; )
                  z.zero((n = K[i])) && (u > 0 ? --u : (o.push(S[n]), --r))
                if (t)
                  for (i = 0; i < ut.length && r > 0; i++)
                    z.zero((n = ut[i])) && (u > 0 ? --u : (o.push(S[n]), --r))
                return o
              }
              function D(r, e) {
                var n,
                  o,
                  i = [],
                  u = 0
                if ((e && e > 0 && (u = e), t))
                  for (n = 0; n < ut.length && r > 0; n++)
                    z.zero((o = ut[n])) && (u > 0 ? --u : (i.push(S[o]), --r))
                for (n = lt; ht > n && r > 0; )
                  z.zero((o = K[n])) && (u > 0 ? --u : (i.push(S[o]), --r)), n++
                return i
              }
              function I(r) {
                function e(e, n, c, l) {
                  function h() {
                    return t
                      ? (B++, void 0)
                      : (++B === T &&
                          ((w = f.arrayWiden(w, (P <<= 1))),
                          (q = f.arrayWiden(q, P)),
                          (T = u(P))),
                        void 0)
                  }
                  t && ((L = c), (c = H.length - e.length), (l = e.length))
                  var p,
                    y,
                    g,
                    b,
                    _,
                    x,
                    m = C,
                    w = t ? [] : o(B, T),
                    E = U,
                    O = D,
                    k = I,
                    A = B,
                    j = 0,
                    $ = 0
                  for (
                    X && (E = k = s),
                      X && (O = k = s),
                      C = new Array(B),
                      B = 0,
                      q = t ? (A ? q : []) : A > 1 ? f.arrayLengthen(q, M) : o(M, T),
                      A && (g = (y = m[0]).key);
                    l > $ && !((b = r(e[$])) >= b);

                  )
                    ++$
                  for (; l > $; ) {
                    for (
                      y && b >= g
                        ? ((_ = y), (x = g), (w[j] = B), (y = m[++j]), y && (g = y.key))
                        : ((_ = { key: b, value: k() }), (x = b)),
                        C[B] = _;
                      x >= b &&
                      ((p = n[$] + (t ? L : c)),
                      t ? (q[p] ? q[p].push(B) : (q[p] = [B])) : (q[p] = B),
                      (_.value = E(_.value, S[p], !0)),
                      z.zeroExcept(p, G, J) || (_.value = O(_.value, S[p], !1)),
                      !(++$ >= l));

                    )
                      b = r(e[$])
                    h()
                  }
                  for (; A > j; ) (C[(w[j] = B)] = m[j++]), h()
                  if (t) for (var N = 0; M > N; N++) q[N] || (q[N] = [])
                  if (B > j)
                    if (t)
                      for (j = 0; L > j; ++j)
                        for (N = 0; N < q[j].length; N++) q[j][N] = w[q[j][N]]
                    else for (j = 0; c > j; ++j) q[j] = w[q[j]]
                  ;(p = F.indexOf(Q)),
                    B > 1 || t
                      ? ((Q = i), (V = v))
                      : (!B && Y && ((B = 1), (C = [{ key: null, value: k() }])),
                        1 === B ? ((Q = a), (V = d)) : ((Q = s), (V = s)),
                        (q = null)),
                    (F[p] = Q)
                }
                function n(r) {
                  if (B > 1 || t) {
                    var e,
                      n,
                      u,
                      f = B,
                      c = C,
                      l = o(f, f)
                    if (t) {
                      for (e = 0, u = 0; M > e; ++e)
                        if (r[e] !== m) {
                          for (q[u] = q[e], n = 0; n < q[u].length; n++) l[q[u][n]] = 1
                          ++u
                        }
                    } else
                      for (e = 0, u = 0; M > e; ++e)
                        r[e] !== m && ((l[(q[u] = q[e])] = 1), ++u)
                    for (C = [], B = 0, e = 0; f > e; ++e)
                      l[e] && ((l[e] = B++), C.push(c[e]))
                    if (B > 1 || t)
                      if (t)
                        for (e = 0; u > e; ++e)
                          for (n = 0; n < q[e].length; ++n) q[e][n] = l[q[e][n]]
                      else for (e = 0; u > e; ++e) q[e] = l[q[e]]
                    else q = null
                    F[F.indexOf(Q)] =
                      B > 1 || t
                        ? ((V = v), (Q = i))
                        : 1 === B
                        ? ((V = d), (Q = a))
                        : (V = Q = s)
                  } else if (1 === B) {
                    if (Y) return
                    for (var h = 0; M > h; ++h) if (r[h] !== m) return
                    ;(C = []), (B = 0), (F[F.indexOf(Q)] = Q = V = s)
                  }
                }
                function i(r, e, n, o, i) {
                  if (!((r === W && e === G) || X)) {
                    var u, f, a, c, s
                    if (t) {
                      for (u = 0, c = n.length; c > u; ++u)
                        if (z.zeroExcept((a = n[u]), G, J))
                          for (f = 0; f < q[a].length; f++)
                            (s = C[q[a][f]]), (s.value = U(s.value, S[a], !1, f))
                      for (u = 0, c = o.length; c > u; ++u)
                        if (z.onlyExcept((a = o[u]), G, J, e, r))
                          for (f = 0; f < q[a].length; f++)
                            (s = C[q[a][f]]), (s.value = D(s.value, S[a], i, f))
                    } else {
                      for (u = 0, c = n.length; c > u; ++u)
                        z.zeroExcept((a = n[u]), G, J) &&
                          ((s = C[q[a]]), (s.value = U(s.value, S[a], !1)))
                      for (u = 0, c = o.length; c > u; ++u)
                        z.onlyExcept((a = o[u]), G, J, e, r) &&
                          ((s = C[q[a]]), (s.value = D(s.value, S[a], i)))
                    }
                  }
                }
                function a(r, t, e, n, o) {
                  if (!((r === W && t === G) || X)) {
                    var i,
                      u,
                      f,
                      a = C[0]
                    for (i = 0, f = e.length; f > i; ++i)
                      z.zeroExcept((u = e[i]), G, J) && (a.value = U(a.value, S[u], !1))
                    for (i = 0, f = n.length; f > i; ++i)
                      z.onlyExcept((u = n[i]), G, J, t, r) &&
                        (a.value = D(a.value, S[u], o))
                  }
                }
                function v() {
                  var r, e, n
                  for (r = 0; B > r; ++r) C[r].value = I()
                  if (t) {
                    for (r = 0; M > r; ++r)
                      for (e = 0; e < q[r].length; e++)
                        (n = C[q[r][e]]), (n.value = U(n.value, S[r], !0, e))
                    for (r = 0; M > r; ++r)
                      if (!z.zeroExcept(r, G, J))
                        for (e = 0; e < q[r].length; e++)
                          (n = C[q[r][e]]), (n.value = D(n.value, S[r], !1, e))
                  } else {
                    for (r = 0; M > r; ++r)
                      (n = C[q[r]]), (n.value = U(n.value, S[r], !0))
                    for (r = 0; M > r; ++r)
                      z.zeroExcept(r, G, J) ||
                        ((n = C[q[r]]), (n.value = D(n.value, S[r], !1)))
                  }
                }
                function d() {
                  var r,
                    t = C[0]
                  for (t.value = I(), r = 0; M > r; ++r) t.value = U(t.value, S[r], !0)
                  for (r = 0; M > r; ++r)
                    z.zeroExcept(r, G, J) || (t.value = D(t.value, S[r], !1))
                }
                function y() {
                  return X && (V(), (X = !1)), C
                }
                function g(r) {
                  var t = N(y(), 0, C.length, r)
                  return R.sort(t, 0, t.length)
                }
                function _(r, t, e) {
                  return (U = r), (D = t), (I = e), (X = !0), j
                }
                function x() {
                  return _(b.reduceIncrement, b.reduceDecrement, l)
                }
                function w(r) {
                  return _(b.reduceAdd(r), b.reduceSubtract(r), l)
                }
                function E(r) {
                  function t(t) {
                    return r(t.value)
                  }
                  return (N = h.by(t)), (R = p.by(t)), j
                }
                function O() {
                  return E(c)
                }
                function k() {
                  return B
                }
                function A() {
                  var r = F.indexOf(Q)
                  return (
                    r >= 0 && F.splice(r, 1),
                    (r = ct.indexOf(e)),
                    r >= 0 && ct.splice(r, 1),
                    (r = $.indexOf(n)),
                    r >= 0 && $.splice(r, 1),
                    (r = st.indexOf(j)),
                    r >= 0 && st.splice(r, 1),
                    j
                  )
                }
                var j = {
                  top: g,
                  all: y,
                  reduce: _,
                  reduceCount: x,
                  reduceSum: w,
                  order: E,
                  orderNatural: O,
                  size: k,
                  dispose: A,
                  remove: A,
                }
                st.push(j)
                var C,
                  q,
                  N,
                  R,
                  U,
                  D,
                  I,
                  L,
                  P = 8,
                  T = u(P),
                  B = 0,
                  Q = s,
                  V = s,
                  X = !0,
                  Y = r === s
                return (
                  arguments.length < 1 && (r = c),
                  F.push(Q),
                  ct.push(e),
                  $.push(n),
                  e(H, K, 0, M),
                  x().orderNatural()
                )
              }
              function L() {
                var r = I(s),
                  t = r.all
                return (
                  delete r.all,
                  delete r.top,
                  delete r.order,
                  delete r.orderNatural,
                  delete r.size,
                  (r.value = function() {
                    return t()[0].value
                  }),
                  r
                )
              }
              function P() {
                st.forEach(function(r) {
                  r.dispose()
                })
                var r = C.indexOf(e)
                return (
                  r >= 0 && C.splice(r, 1),
                  (r = C.indexOf(n)),
                  r >= 0 && C.splice(r, 1),
                  (r = $.indexOf(d)),
                  r >= 0 && $.splice(r, 1),
                  (z.masks[G] &= J),
                  k()
                )
              }
              if ('string' == typeof r) {
                var T = r
                r = function(r) {
                  return x(r, T)
                }
              }
              var W,
                J,
                G,
                B,
                H,
                K,
                Q,
                V,
                X,
                Y,
                Z,
                rt,
                tt,
                et,
                nt,
                ot,
                it = {
                  filter: w,
                  filterExact: E,
                  filterRange: O,
                  filterFunction: j,
                  filterAll: k,
                  currentFilter: N,
                  hasCurrentFilter: R,
                  top: U,
                  bottom: D,
                  group: I,
                  groupAll: L,
                  dispose: P,
                  remove: P,
                  accessor: r,
                  id: function() {
                    return B
                  },
                },
                ut = [],
                ft = g.by(function(r) {
                  return Q[r]
                }),
                at = a.filterAll,
                ct = [],
                st = [],
                lt = 0,
                ht = 0,
                pt = 0
              C.unshift(e), C.push(n), $.push(d)
              var vt = z.add()
              return (
                (G = vt.offset),
                (W = vt.one),
                (J = ~W),
                (B = (G << 7) | (Math.log(W) / Math.log(2))),
                e(S, 0, M),
                n(S, 0, M),
                it
              )
            }
            function _() {
              function r(r, t) {
                var e
                if (!v)
                  for (e = t; M > e; ++e)
                    (a = c(a, S[e], !0)), z.zero(e) || (a = s(a, S[e], !1))
              }
              function t(r, t, e, n, o) {
                var i, u, f
                if (!v) {
                  for (i = 0, f = e.length; f > i; ++i)
                    z.zero((u = e[i])) && (a = c(a, S[u], o))
                  for (i = 0, f = n.length; f > i; ++i)
                    z.only((u = n[i]), t, r) && (a = s(a, S[u], o))
                }
              }
              function e() {
                var r
                for (a = h(), r = 0; M > r; ++r)
                  (a = c(a, S[r], !0)), z.zero(r) || (a = s(a, S[r], !1))
              }
              function n(r, t, e) {
                return (c = r), (s = t), (h = e), (v = !0), p
              }
              function o() {
                return n(b.reduceIncrement, b.reduceDecrement, l)
              }
              function i(r) {
                return n(b.reduceAdd(r), b.reduceSubtract(r), l)
              }
              function u() {
                return v && (e(), (v = !1)), a
              }
              function f() {
                var e = F.indexOf(t)
                return (
                  e >= 0 && F.splice(e, 1),
                  (e = C.indexOf(r)),
                  e >= 0 && C.splice(e, 1),
                  p
                )
              }
              var a,
                c,
                s,
                h,
                p = {
                  reduce: n,
                  reduceCount: o,
                  reduceSum: i,
                  value: u,
                  dispose: f,
                  remove: f,
                },
                v = !0
              return F.push(t), C.push(r), r(S, 0, M), o()
            }
            function w() {
              return M
            }
            function E() {
              return S
            }
            function O(r) {
              var t = [],
                n = 0,
                o = e(r || [])
              for (n = 0; M > n; n++) z.zeroExceptMask(n, o) && t.push(S[n])
              return t
            }
            function k(r) {
              return 'function' != typeof r
                ? (console.warn('onChange callback parameter must be a function!'),
                  void 0)
                : (q.push(r),
                  function() {
                    q.splice(q.indexOf(r), 1)
                  })
            }
            function A(r) {
              for (var t = 0; t < q.length; t++) q[t](r)
            }
            var z,
              j = {
                add: r,
                remove: t,
                dimension: d,
                groupAll: _,
                size: w,
                all: E,
                allFiltered: O,
                onChange: k,
                isElementFiltered: n,
              },
              S = [],
              M = 0,
              F = [],
              C = [],
              $ = [],
              q = []
            return (z = new f.bitarray(0)), arguments.length ? r(arguments[0]) : j
          }
          function o(r, t) {
            return (257 > t ? f.array8 : 65537 > t ? f.array16 : f.array32)(r)
          }
          function i(r) {
            for (var t = o(r, r), e = -1; ++e < r; ) t[e] = e
            return t
          }
          function u(r) {
            return 8 === r ? 256 : 16 === r ? 65536 : 4294967296
          }
          var f = r('./array'),
            a = r('./filter'),
            c = r('./identity'),
            s = r('./null'),
            l = r('./zero'),
            h = r('./heapselect'),
            p = r('./heap'),
            v = r('./bisect'),
            d = r('./insertionsort'),
            y = r('./permute'),
            g = r('./quicksort'),
            b = r('./reduce'),
            _ = r('./../package.json'),
            x = r('lodash.result'),
            m = -1
          ;(e.crossfilter = n),
            (e.crossfilter.heap = p),
            (e.crossfilter.heapselect = h),
            (e.crossfilter.bisect = v),
            (e.crossfilter.insertionsort = d),
            (e.crossfilter.permute = y),
            (e.crossfilter.quicksort = g),
            (e.crossfilter.version = _.version)
        },
        {
          './../package.json': 3,
          './array': 4,
          './bisect': 5,
          './filter': 7,
          './heap': 8,
          './heapselect': 9,
          './identity': 10,
          './insertionsort': 11,
          './null': 12,
          './permute': 13,
          './quicksort': 14,
          './reduce': 15,
          './zero': 16,
          'lodash.result': 2,
        },
      ],
      7: [
        function(r, t) {
          'use strict'
          function e(r, t) {
            return function(e) {
              var n = e.length
              return [r.left(e, t, 0, n), r.right(e, t, 0, n)]
            }
          }
          function n(r, t) {
            var e = t[0],
              n = t[1]
            return function(t) {
              var o = t.length
              return [r.left(t, e, 0, o), r.left(t, n, 0, o)]
            }
          }
          function o(r) {
            return [0, r.length]
          }
          t.exports = { filterExact: e, filterRange: n, filterAll: o }
        },
        {},
      ],
      8: [
        function(r, t) {
          'use strict'
          function e(r) {
            function t(r, t, e) {
              for (var o = e - t, i = (o >>> 1) + 1; --i > 0; ) n(r, i, o, t)
              return r
            }
            function e(r, t, e) {
              for (var o, i = e - t; --i > 0; )
                (o = r[t]), (r[t] = r[t + i]), (r[t + i] = o), n(r, 1, i, t)
              return r
            }
            function n(t, e, n, o) {
              for (
                var i, u = t[--o + e], f = r(u);
                (i = e << 1) <= n &&
                (n > i && r(t[o + i]) > r(t[o + i + 1]) && i++, !(f <= r(t[o + i])));

              )
                (t[o + e] = t[o + i]), (e = i)
              t[o + e] = u
            }
            return (t.sort = e), t
          }
          var n = r('./identity')
          ;(t.exports = e(n)), (t.exports.by = e)
        },
        { './identity': 10 },
      ],
      9: [
        function(r, t) {
          'use strict'
          function e(r) {
            function t(t, n, o, i) {
              var u,
                f,
                a,
                c = new Array((i = Math.min(o - n, i)))
              for (f = 0; i > f; ++f) c[f] = t[n++]
              if ((e(c, 0, i), o > n)) {
                u = r(c[0])
                do r((a = t[n])) > u && ((c[0] = a), (u = r(e(c, 0, i)[0])))
                while (++n < o)
              }
              return c
            }
            var e = o.by(r)
            return t
          }
          var n = r('./identity'),
            o = r('./heap')
          ;(t.exports = e(n)), (t.exports.by = e)
        },
        { './heap': 8, './identity': 10 },
      ],
      10: [
        function(r, t) {
          'use strict'
          function e(r) {
            return r
          }
          t.exports = e
        },
        {},
      ],
      11: [
        function(r, t) {
          'use strict'
          function e(r) {
            function t(t, e, n) {
              for (var o = e + 1; n > o; ++o) {
                for (var i = o, u = t[o], f = r(u); i > e && r(t[i - 1]) > f; --i)
                  t[i] = t[i - 1]
                t[i] = u
              }
              return t
            }
            return t
          }
          var n = r('./identity')
          ;(t.exports = e(n)), (t.exports.by = e)
        },
        { './identity': 10 },
      ],
      12: [
        function(r, t) {
          'use strict'
          function e() {
            return null
          }
          t.exports = e
        },
        {},
      ],
      13: [
        function(r, t) {
          'use strict'
          function e(r, t, e) {
            for (
              var n = 0,
                o = t.length,
                i = e ? JSON.parse(JSON.stringify(r)) : new Array(o);
              o > n;
              ++n
            )
              i[n] = r[t[n]]
            return i
          }
          t.exports = e
        },
        {},
      ],
      14: [
        function(r, t) {
          function e(r) {
            function t(r, t, o) {
              return (i > o - t ? n : e)(r, t, o)
            }
            function e(e, n, o) {
              var i,
                u = 0 | ((o - n) / 6),
                f = n + u,
                a = o - 1 - u,
                c = (n + o - 1) >> 1,
                s = c - u,
                l = c + u,
                h = e[f],
                p = r(h),
                v = e[s],
                d = r(v),
                y = e[c],
                g = r(y),
                b = e[l],
                _ = r(b),
                x = e[a],
                m = r(x)
              p > d && ((i = h), (h = v), (v = i), (i = p), (p = d), (d = i)),
                _ > m && ((i = b), (b = x), (x = i), (i = _), (_ = m), (m = i)),
                p > g && ((i = h), (h = y), (y = i), (i = p), (p = g), (g = i)),
                d > g && ((i = v), (v = y), (y = i), (i = d), (d = g), (g = i)),
                p > _ && ((i = h), (h = b), (b = i), (i = p), (p = _), (_ = i)),
                g > _ && ((i = y), (y = b), (b = i), (i = g), (g = _), (_ = i)),
                d > m && ((i = v), (v = x), (x = i), (i = d), (d = m), (m = i)),
                d > g && ((i = v), (v = y), (y = i), (i = d), (d = g), (g = i)),
                _ > m && ((i = b), (b = x), (x = i), (i = _), (_ = m), (m = i))
              var w = v,
                E = d,
                O = b,
                k = _
              ;(e[f] = h), (e[s] = e[n]), (e[c] = y), (e[l] = e[o - 1]), (e[a] = x)
              var A = n + 1,
                z = o - 2,
                j = k >= E && E >= k
              if (j)
                for (var S = A; z >= S; ++S) {
                  var M = e[S],
                    F = r(M)
                  if (E > F) S !== A && ((e[S] = e[A]), (e[A] = M)), ++A
                  else if (F > E)
                    for (;;) {
                      var C = r(e[z])
                      {
                        if (!(C > E)) {
                          if (E > C) {
                            ;(e[S] = e[A]), (e[A++] = e[z]), (e[z--] = M)
                            break
                          }
                          ;(e[S] = e[z]), (e[z--] = M)
                          break
                        }
                        z--
                      }
                    }
                }
              else
                !(function() {
                  for (var t = A; z >= t; t++) {
                    var n = e[t],
                      o = r(n)
                    if (E > o) t !== A && ((e[t] = e[A]), (e[A] = n)), ++A
                    else if (o > k)
                      for (;;) {
                        var i = r(e[z])
                        {
                          if (!(i > k)) {
                            E > i
                              ? ((e[t] = e[A]), (e[A++] = e[z]), (e[z--] = n))
                              : ((e[t] = e[z]), (e[z--] = n))
                            break
                          }
                          if ((z--, t > z)) break
                        }
                      }
                  }
                })()
              return (
                (e[n] = e[A - 1]),
                (e[A - 1] = w),
                (e[o - 1] = e[z + 1]),
                (e[z + 1] = O),
                t(e, n, A - 1),
                t(e, z + 2, o),
                j
                  ? e
                  : (f > A &&
                      z > a &&
                      !(function() {
                        for (var t, n; (t = r(e[A])) <= E && t >= E; ) ++A
                        for (; (n = r(e[z])) <= k && n >= k; ) --z
                        for (var o = A; z >= o; o++) {
                          var i = e[o],
                            u = r(i)
                          if (E >= u && u >= E)
                            o !== A && ((e[o] = e[A]), (e[A] = i)), A++
                          else if (k >= u && u >= k)
                            for (;;) {
                              n = r(e[z])
                              {
                                if (!(k >= n && n >= k)) {
                                  E > n
                                    ? ((e[o] = e[A]), (e[A++] = e[z]), (e[z--] = i))
                                    : ((e[o] = e[z]), (e[z--] = i))
                                  break
                                }
                                if ((z--, o > z)) break
                              }
                            }
                        }
                      })(),
                    t(e, A, z + 1))
              )
            }
            var n = o.by(r)
            return t
          }
          var n = r('./identity'),
            o = r('./insertionsort'),
            i = 32
          ;(t.exports = e(n)), (t.exports.by = e)
        },
        { './identity': 10, './insertionsort': 11 },
      ],
      15: [
        function(r, t) {
          'use strict'
          function e(r) {
            return r + 1
          }
          function n(r) {
            return r - 1
          }
          function o(r) {
            return function(t, e) {
              return t + +r(e)
            }
          }
          function i(r) {
            return function(t, e) {
              return t - r(e)
            }
          }
          t.exports = {
            reduceIncrement: e,
            reduceDecrement: n,
            reduceAdd: o,
            reduceSubtract: i,
          }
        },
        {},
      ],
      16: [
        function(r, t) {
          'use strict'
          function e() {
            return 0
          }
          t.exports = e
        },
        {},
      ],
    },
    {},
    [1]
  )(1)
})
